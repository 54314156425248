import { Link } from "gatsby";
import React from "react";
import Layout from "../components/layouts/Layout";
import SEO from "../components/SEO";

const NotFoundPage = () => (
  <Layout>
    <div className="pt-16 text-center">
      <SEO title="404 Not Found" />
      <h1>Hmm...not sure what you're looking for.</h1>
      <p>There's nothing here. Are you sure you have the right address?</p>
      <p>
        <Link to="/">Back to home</Link>
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
